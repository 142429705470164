<!--
 * @Author: lbh
 * @Date: 2022-03-18 10:40:06
 * @LastEditors: lbh
 * @LastEditTime: 2022-03-26 18:00:13
 * @Description: file content
-->
<template>
  <div class="map-box">
    <div class="big-title">配送範例</div>
    <div class="map_box">
      <!-- 表格 -->
      <table
        class="table-box"
        cellpadding="0"
        cellspacing="0"
      >
        <tr>
          <td colspan="2">客人</td>
          <td colspan="2">Gingersoft外賣系統</td>
          <td>外賣平台</td>
          <td>節省成本</td>
        </tr>
        <tr>
          <td>距離</td>
          <td>最低消費</td>
          <td>第三方運費</td>
          <td>交易費</td>
          <td>30%</td>
          <td>百分比</td>
        </tr>
        <tr>
          <td>300 m(小圈)</td>
          <td>$ 200</td>
          <td colspan="2">$ 41</td>
          <td>$ 60</td>
          <td>31.67%</td>
        </tr>
        <tr>
          <td>1.5 km(中圈)</td>
          <td>$ 300</td>
          <td colspan="2">$ 64</td>
          <td>$ 90</td>
          <td>28.89%</td>
        </tr>
        <tr>
          <td>10 km(大圈)</td>
          <td>$ 800</td>
          <td colspan="2">$ 99-174</td>
          <td>$ 240</td>
          <td>27.5-58.75%</td>
        </tr>
      </table>
      <div id="myMapSelf"></div>
    </div>
    <div class="big-desc">*運費及交易費僅供參考，以落單實際情況為準</div>
  </div>
</template>

<script>
export default {
  name: "self-map",
  data () {
    return {
      // 地圖對象
      myMapSelf: null,
      // 中心坐標
      myCenterSelf: {
        lat: 22.3072458588,
        lng: 114.173291988
      },
      markersArr: [], // 標記 對象
      shutflightPathSelfFunc: [], // 合併
      is_confirm: false,
      Popup: null,
    }
  },

  mounted () {
    // this.initMap();
    google.maps.event.addDomListener(window, 'load', this.initMap);
  },
  methods: {
    initMap () {
      this.getLoca();
      let option = {
        center: this.myCenterSelf,
        disableDefaultUI: true,
        zoomControl: true,
        zoom: 12
      };
      let marker = null
      this.myMapSelf = new google.maps.Map(document.getElementById('myMapSelf'), option);
    },
    getLoca () {
      let self = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            self.myMapSelf.panTo(position);
          },
          function (error) {
            let innerHTML = '';
            switch (error.code) {
              case error.PERMISSION_DENIED:
                innerHTML = '用户拒绝对获取地理位置的请求。';
                break;
              case error.POSITION_UNAVAILABLE:
                innerHTML = '位置信息是不可用的。';
                break;
              case error.TIMEOUT:
                innerHTML = '请求用户地理位置超时。';
                break;
              case error.UNKNOWN_ERROR:
                innerHTML = '未知错误。';
                break;
            }
            // self.$message.error(innerHTML);
          }
        );
      } else {
        // self.$message.error('该浏览器不支持获取地理位置');
      }
    },
  }
}
</script>

<style lang="less" scoped>
.map-box {
  // width: calc(100% - 200px);
  padding: 0 100px;
  background: #fff;
  .big-title {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
    color: #18181a;
    padding: 120px 0 60px 0;
  }
  .big-desc {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #606266;
    text-align: center;
    margin-top: 20px;
  }
  .map_box {
    position: relative;
    .btn-box {
      position: absolute;
      top: 40px;
      left: 40px;
      z-index: 10;
      & /deep/ .el-button {
        transition: 0.3s;
        &:hover {
          transform: scale(1);
        }
      }
      .theme_bg_color {
        color: #fff;
      }
      .theme_color {
        background-color: #fff;
        transform: scale(0.8);
      }
    }
    .table-box {
      position: absolute;
      width: 80%;
      bottom: 20px;
      left: 10%;
      z-index: 10;

      td {
        text-align: center;
        height: 36px;
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        border-left: 0.5px dashed #b3b3b3;
        border-bottom: 0.5px dashed #b3b3b3;
        min-width: 16.6%;
      }
      tr {
        &:first-child {
          td {
            font-size: 16px;
          }
        }
        background-color: rgba(21, 22, 24, 0.49);
        color: #ffffff;
        &.active {
          background-color: #fff !important;
        }
        td {
          &:nth-child(1) {
            border-left: none;
          }
        }
        &:last-child {
          td {
            border-bottom: none;
          }
        }
        &:nth-child(1) {
          td {
            &:nth-child(1),
            &:nth-child(2) {
              width: 33.33%;
            }
            &:nth-child(3),
            &:nth-child(4) {
              width: 16.6%;
            }
          }
        }
        &:nth-child(2) {
          td {
            width: 16.6%;
          }
        }
      }
    }
    #myMapSelf {
      height: 600px;
    }
  }
}
</style>